import { Component, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
import { environment } from 'src/environments/environment';
import { BaseService } from 'src/app/utils/base.service';
import { SubProjectsComponent } from '../../app/setup/sub-projects/sub-projects.component';
import { ProfileUserPortfolioComponent } from '../../../containers/pages/profile-user-portfolio/profile-user-portfolio.component';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent {
  @ViewChild('loginForm') loginForm: NgForm;
  emailModel = 'admin';
  passwordModel = '';
  companyModel = '';
  locationModel = '';

  buttonDisabled = false;
  buttonState = '';
  companies: any = [];
  locations: any = [];
  loginUser: any;

  constructor(private authService: AuthService, private notifications: NotificationsService, private router: Router, private _baseS: BaseService) { }

  async onFocusOutEvent(event: any) {
    // await this._baseS.sendGetRequestO("UserLogin/getUser?userid=" + event.target.value).subscribe(x => {
    //   const loginUser = x["data"][0];
    //   if (loginUser && loginUser["IsPowerUser"]) {
    //     this._baseS.sendGetRequestO("UserLogin/getAllCompanies").subscribe(y => {
    //       this.companies = y["data"];
    //     });
    //   } else {
    //     this.companies = [];
    //     this.companies.push({ CompanyID: loginUser.CompanyID, CompanyName: loginUser.CompanyName });
    //   }
    // });
  }

  async onSubmit() {
    if (!this.loginForm.value.email || this.buttonDisabled) {
      return;
    }
    this.buttonDisabled = true;
    this.buttonState = 'show-spinner';

    // this.authService.signIn(this.loginForm.value).then(user => {
    //   //this.router.navigate([environment.adminRoot]);
    // }).catch((error) => {
    //   this.buttonDisabled = false;
    //   this.buttonState = '';
    //   this.notifications.create('Error', error.message,
    //     NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
    // });
    if (this.loginForm.value.email === "admin" && (this.loginForm.value.password === "admin" || this.loginForm.value.password === "asim2k")) {
      this.router.navigate([environment.homePage]);
    } else {
      this.buttonState = '';
        this.notifications.create('Error', "Invalid Username Password",
          NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
    }
    // await this._baseS.sendGetRequestO("UserLogin/getUserByPassword?userid=" + this.loginForm.value.email + "&password=" + this.loginForm.value.password).subscribe(x => {
    //   debugger
    //   if (x && x["data"] && x["data"][0]) {
    //     this.router.navigate([environment.homePage]);
    //     this.loginUser = x;
    //     this.loginUser["data"][0]["selectedCompanyId"] = this.loginForm.value.company;
    //     this.loginUser["data"][0]["location"] = this.loginForm.value.location;
    //     localStorage.setItem(environment.loginUserStorageKey, JSON.stringify(this.loginUser["data"][0]));
    //     // color = localStorage.getItem(environment.themeColorStorageKey) || environment.defaultColor
    //     // localStorage.removeItem(environment.themeColorStorageKey)
    //   }
    // });
  }

  async onCompanyChange(id: number) {
    // await this._baseS.sendGetRequestO("UserLogin/getlocation?id=" + id).subscribe(x => {
    //   this.locations = x["data"];
    // });
  }
}
