import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { NotificationsService, NotificationType } from 'angular2-notifications';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  //serverUrl: string = environment.prodUrl;
  serverUrl: string = environment.devUrl;
  constructor(private httpClient: HttpClient,
    private notifications: NotificationsService) { }

  token: string = environment.token;
  //environment[this.env].serviceUrl

  public async sendGetRequestP(url) {
    const headers = new HttpHeaders()
      .set('token', environment.token);

    return await this.httpClient.get(this.serverUrl + url, { 'headers': headers }).toPromise();
  }

  public sendGetRequestO(url) {
    const headers = new HttpHeaders()
      .set('token', environment.token);

    return this.httpClient.get(this.serverUrl + url, { 'headers': headers });
  }

  public add(url, data) {
    const headers = new HttpHeaders()
      .set('token', environment.token);

    return this.httpClient.post(this.serverUrl + url, data, { 'headers': headers });
  }

  public delete(url) {
    const headers = new HttpHeaders()
      .set('token', environment.token);

    return this.httpClient.delete(this.serverUrl + url, { 'headers': headers });
  }

  getProperty(pageUrl: string, pageSize: number = 10, currentPage: number = 1, search: string = '', orderBy: string = '') {
    const url = this.serverUrl + pageUrl;
    let params = new HttpParams();
    params = params.append('pageSize', pageSize + '');
    params = params.append('currentPage', currentPage + '');
    params = params.append('search', search);
    params = params.append('orderBy', orderBy);

    return this.httpClient.get(url, { params })
      .pipe(
        map((res: IResponse) => {
          return res;
        }),
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  onSuccess(msg): void {
    this.notifications.create('Success', msg, NotificationType.Success, { timeOut: 3000, showProgressBar: true });
  }
}

export interface IResponse {
  data: IPropertyNature[];
  status: boolean;
  totalItem: number;
  totalPage: number;
  pageSize: string;
  currentPage: string;
}

export interface IPropertyNature {
  id: number;
  title: string;
  img: string;
  category: string;
  status: string;
  statusColor: string;
  description: string;
  sales: number;
  stock: number;
  date: string;
}