<div class="card auth-card">
  <div class="position-relative image-side ">
    <p class=" text-white h2">MAGIC IS IN THE DETAILS</p>
    <p class="white mb-0">
      Please enter a new password.
      <br>If you are not a member, please
      <a routerLink="/user/register" class="white white-underline-link">register</a>.
    </p>
  </div>
  <div class="form-side">
    <a class="c-pointer">
      <span class="logo-single"></span>
    </a>
    <h6 class="mb-4">Reset Password</h6>

    <form #resetForm="ngForm" novalidate (ngSubmit)="onSubmit()">
      <label class="form-group has-float-label">
        <input class="form-control" type="text" placeholder="" required ngModel #code="ngModel" name="code" />
        <span>{{ 'user.code' | translate }}</span>
        <div *ngIf="!code.valid && resetForm.submitted" class="invalid-tooltip">Code is required!</div>
      </label>
      <label class="form-group has-float-label">
        <input class="form-control" type="password" placeholder="" required ngModel #newPassword="ngModel"
          name="newPassword" />
        <span>{{ 'user.password' | translate }}</span>
        <div *ngIf="!newPassword.valid && resetForm.submitted" class="invalid-tooltip">Password is required!</div>
      </label>
      <div class="d-flex justify-content-between align-items-center">
        <a routerLink="/user/forgot-password">{{ 'user.forgot-password' | translate }}?</a>
        <app-state-button [btnClass]="'btn btn-primary btn-lg btn-shadow'" [currentState]="buttonState"
          [isDisabled]="buttonDisabled" click="onLoginClick()">
          {{ 'user.reset-password-button' | translate | uppercase }}
        </app-state-button>
      </div>
    </form>

  </div>
</div>
<simple-notifications></simple-notifications>
