<div class="card auth-card">
  <div class="position-relative image-side ">
    <p class=" text-white h2">MAGIC IS IN THE DETAILS</p>
    <p class="white mb-0">
      Please use your e-mail to reset your password.
      <br>If you are not a member, please
      <a routerLink="/user/register" class="white white-underline-link">register</a>.
    </p>
  </div>
  <div class="form-side">
    <a class="c-pointer">
      <span class="logo-single"></span>
    </a>
    <h6 class="mb-4">Forgot Password</h6>

    <form #passwordForm="ngForm" novalidate  (ngSubmit)="onSubmit()">
      <label class="form-group has-float-label">
        <input class="form-control" required email ngModel #email="ngModel" name="email"/>
        <span>{{ 'user.email' | translate }}</span>
        <div *ngIf="!email.valid && passwordForm.submitted" class="invalid-tooltip">Email is required!</div>
        <div *ngIf="email?.errors?.email && passwordForm.submitted" class="invalid-tooltip">Email must be a valid address!</div>
      </label>
      <div class="d-flex justify-content-between align-items-center">
        <a routerLink="/user/login">{{ 'user.login-button' | translate }}</a>
        <app-state-button [btnClass]="'btn btn-primary btn-lg btn-shadow'" [currentState]="buttonState"
          [isDisabled]="buttonDisabled" click="onLoginClick()">
          {{ 'user.reset-password-button' | translate | uppercase }}
        </app-state-button>
      </div>
    </form >

  </div>
</div>
<simple-notifications></simple-notifications>
