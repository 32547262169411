import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
}

const data: IMenuItem[] = [
  {
    icon: 'simple-icon-speedometer',
    label: 'Dashboard',
    to: `${adminRoot}/dashboard/main`,
    // subs: [
    //   {
    //     icon: 'simple-icon-paper-plane',
    //     label: 'Dashboard',
    //     to: `${adminRoot}/dashboard/main`,
    //   }
    // ]
  },
  {
    icon: 'simple-icon-grid',
    label: 'Setup Admin',
    to: `${adminRoot}/setupAdmin`,
    subs: [
      {
        icon: 'simple-icon-globe-alt',
        label: 'Country',
        to: `${adminRoot}/setupAdmin/country`,
      },
      {
        icon: 'iconsminds-location-2',
        label: 'City',
        to: `${adminRoot}/setupAdmin/city`,
      },
      {
        icon: 'simple-icon-organization',
        label: 'Department',
        to: `${adminRoot}/setupAdmin/department`,
      },
      {
        icon: 'iconsminds-profile',
        label: 'Designation',
        to: `${adminRoot}/setupAdmin/designation`,
      },
      {
        icon: 'iconsminds-business-man-woman',
        label: 'Employee',
        to: `${adminRoot}/setupAdmin/employee`,
      },
      {
        icon: 'iconsminds-full-screen-2',
        label: 'Division',
        to: `${adminRoot}/setupAdmin/division`,
      },
      {
        icon: 'iconsminds-project',
        label: 'Vehicle Type',
        to: `${adminRoot}/setupAdmin/vehicleType`,
      },
    ]
  },
  {
    icon: 'simple-icon-layers',
    label: 'Setup',
    to: `${adminRoot}/setup`,
    subs: [

      {
        icon: 'iconsminds-notepad',
        label: 'Vehicle Registration',
        to: `${adminRoot}/setup/vehicleRegistration`,
      },

      {
        icon: 'iconsminds-management',
        label: 'Insurance Com. Reg.',
        to: `${adminRoot}/setup/insuranceCompRig`,
      },
      {
        icon: 'iconsminds-letter-open',
        label: 'Veh. Insurance Policy',
        to: `${adminRoot}/setup/vehicleInsurancePolicy`,
      },
      {
        icon: 'iconsminds-engineering',
        label: 'Mech. / Vender Reg.',
        to: `${adminRoot}/setup/mechanicsReg`,
      },
      {
        icon: 'iconsminds-business-man',
        label: 'Driver Registration',
        to: `${adminRoot}/setup/driverReg`,
      },
      {
        icon: 'iconsminds-id-card',
        label: 'Driver Driving Licenses',
        to: `${adminRoot}/setup/drivingLicense`,
      },

    ],
  },
  {
    icon: 'simple-icon-list',
    label: 'Transaction',
    to: `${adminRoot}/transaction`,
    // roles: [UserRole.Editor],
    subs: [
      {
        icon: 'iconsminds-jeep',
        label: 'Veh. Handover',
        to: `${adminRoot}/transaction/handover`,
      }, {
        icon: 'iconsminds-gas-pump',
        label: 'Vehicles Fueling',
        to: `${adminRoot}/transaction/vehicleFueling`,
      },
      {
        icon: 'simple-icon-social-steam',
        label: 'Veh. Yearly Inc. Policy',
        to: `${adminRoot}/transaction/serviceEntries`,
      },
      {
        icon: 'iconsminds-file-copy',
        label: 'Traffic Challans',
        to: `${adminRoot}/transaction/trafficChallan`,
      },
      {
        icon: 'iconsminds-broke-link-2',
        label: 'Vehicle Accidents',
        to: `${adminRoot}/transaction/vehicleAccident`,
      },
      {
        icon: 'simple-icon-book-open',
        label: 'Work Order',
        to: `${adminRoot}/transaction/workOrder`,
      },
      {
        icon: 'iconsminds-dollar',
        label: 'Bill Payment',
        to: `${adminRoot}/transaction/billPayment`,
      },
      {
        icon: 'iconsminds-key',
        label: 'Vehicles Return',
        to: `${adminRoot}/transaction/vehicleReturn`,
      },
    ],
  },
  {
    icon: 'iconsminds-monitor-analytics',
    label: 'Report',
    to: `${adminRoot}`,
    // roles: [UserRole.Editor],
    subs: [

    ],
  },
  {
    icon: 'simple-icon-user-follow',
    label: 'Admin',
    to: `${adminRoot}`,

  },
];
export default data;
