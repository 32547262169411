import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    //loadChildren: () => import('./views/views.module').then(m => m.ViewsModule)
    loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
  },
  // {
  //   path: 'application',
  //   loadChildren: () => import('./views/app/second-menu/second-menu.module').then(m => m.SecondMenuModule)
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
