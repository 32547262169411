<div class="card auth-card">
  <div class="position-relative image-side ">
    <p class=" text-white h2">MAGIC IS IN THE DETAILS</p>
    <p class="white mb-0">
      Please use your credentials to login.
      <br>If you are not a member, please
      <a routerLink="/user/register" class="white white-underline-link">register</a>.
    </p>
  </div>
  <div class="form-side">
    <a class="c-pointer">
      <span class="logo-single"></span>
    </a>
    <h6 class="mb-4">Login</h6>

    <form #loginForm="ngForm" novalidate (ngSubmit)="onSubmit()">
      <label class="form-group has-float-label">
        <input class="form-control" (focusout)="onFocusOutEvent($event)" required ngModel #email="ngModel" name="email" [(ngModel)]="emailModel" style="height: 40px !important;" />
        <span>Email</span>
        <div *ngIf="!email.valid && loginForm.submitted" class="invalid-tooltip">Email is required!</div>
      </label>
      <label class="form-group has-float-label">
        <input class="form-control" type="password" required placeholder="" ngModel #password="ngModel" style="height: 40px !important;"
          name="password" [(ngModel)]="passwordModel" />
        <span>Password</span>
        <div *ngIf="!password.valid && loginForm.submitted" class="invalid-tooltip">Password is required!</div>
      </label>
      <label class="form-group has-float-label">
        <select class="form-control" style="height: 40px !important;">
          <option value="1">
            ABC
          </option>
        </select>
        <!-- <select class="form-control" ngModel required (change)="onCompanyChange(company.value)" #company="ngModel" name="company" [(ngModel)]="companyModel">
          <option *ngFor="let order of companies;" [value]="order.CompanyID">
            {{order.CompanyName}}
          </option>
        </select> -->
        <span>Company</span>
        <!-- <div *ngIf="!company.valid && loginForm.submitted" class="invalid-tooltip">Company is required!</div> -->
      </label>
      <label class="form-group has-float-label">
        <!-- <select class="form-control" ngModel required #location="ngModel" name="location" [(ngModel)]="locationModel">
          <option *ngFor="let order of locations;" [value]="order.LocationID">
            {{order.LocationName}}
          </option>
        </select> -->
        <select class="form-control" style="height: 40px !important;">
          <option value="1">
            Head Office
          </option>
        </select>
        <span>Location</span>
        <!-- <div *ngIf="!company.valid && loginForm.submitted" class="invalid-tooltip">Company is required!</div> -->
      </label>
      <div class="d-flex justify-content-between align-items-center">
        <a routerLink="/user/forgot-password">{{ 'user.forgot-password' | translate }}?</a>
        <app-state-button [btnClass]="'btn btn-primary btn-lg btn-shadow'" [currentState]="buttonState"
          [isDisabled]="buttonDisabled" click="onLoginClick()">
          LOGIN
        </app-state-button>
      </div>
    </form>

  </div>
</div>
<simple-notifications></simple-notifications>